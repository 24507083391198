import {
    createApp
} from 'vue'
import App from './App.vue'
// 导入Antd
import Antd from 'ant-design-vue'
// 导入Antd样式
import 'ant-design-vue/dist/antd.css'
import router from "./router"
import '@/router/permission'
import requ from '@/utils/request'

import "@/assets/css/index.scss"
const app = createApp(App)
app.baseUrl = requ.baseURL
// 挂载Antd
app.use(Antd)
app.use(router)
app.mount('#app')
// App.use(Antd)
// createApp(App).mount('#app')