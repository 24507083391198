import {
    createRouter,
    createWebHashHistory
} from 'vue-router'

import Layout from '@/layout'
const routes = [{
        path: '/',
        redirect: '/option'
    },
    {
        name: 'option',
        path: '/option',
        component: () => import("@/view/login/option.vue")
    },
    {
        name: 'login',
        path: '/login',
        component: () => import("@/view/login")
    }, {
        name: 'business',
        path: '/business',
        redirect: '/business/goods',
        component: Layout,
        meta: {
            title: '资料维护'
        },
        children: [{
                path: 'goods',
                name: 'goods',
                meta: {
                    title: '商家中心',
                    hidden: true
                },
                component: () => import("@/view/business"),
                children: [{
                    name: 'AddInfomation',
                    path: 'AddInfomation',
                    meta: {
                        title: '商家信息',
                        hidden: false
                    },
                    component: () => import("@/view/business/AddInfomation")
                }],
            },
            {
                path: 'BasicsGoods1',
                name: 'BasicsGoods1',
                meta: {
                    title: '基础产品',
                    hidden: true
                },
                component: () => import("@/view/product"),
                children: [{
                        path: 'goodsInformation',
                        name: 'goodsInformation',
                        meta: {
                            title: '商品详情',
                            hidden: false
                        },
                        component: () => import("@/view/product/goodsInformation")
                    },
                    {
                        path: 'declarationInfo',
                        name: 'declarationInfo',
                        meta: {
                            title: '报关信息',
                            hidden: false,
                            show: true
                        },
                        component: () => import("@/view/product/declarationInfo"),
                        children: [{
                            path: 'AddDeclared',
                            name: 'AddDeclared',
                            meta: {
                                show: false,
                                title: '商品赋码详情',
                                hidden: false
                            },
                            component: () => import("@/view/product/declaredGoods/AddDeclared")
                        }],
                    }

                ]

            }, {
                meta: {
                    title: '生产企业'
                },
                path: 'manufacturing',
                name: "manufacturing",
                component: () => import("@/view/enterprise/manufacturing")
            },
        ]
    },
    {
        name: 'product',
        path: '/product',
        redirect: '/product/billOfEntry',
        component: Layout,
        meta: {
            title: '产品赋码'
        },
        children: [
            {
                meta:{
                    title:'报关单维护',
                    hidden:true
                },
                path:'billOfEntry',
                name:'billOfEntry',
                component:()=>import("@/view/product/billOfEntry"),
                children:[
                    {
                        meta:{
                            title:'编辑报关单',
                            hidden:false,
                        },
                        path:'addBillOfEntry',
                        name:'addBillOfEntry',
                        component:()=>import("@/view/product/billOfEntry/addBillOfEntry.vue")
                    }
                ]
            },
            {
                path: 'BasicsGoods',
                name: 'BasicsGoods',
                meta: {
                    title: '信息采集',
                    hidden: true
                },
                component: () => import("@/view/product"),
                children: [
                    {
                        path: 'declarationInfo',
                        name: 'declarationInfo',
                        meta: {
                            title: '报关信息',
                            hidden: false,
                            show: true
                        },
                        component: () => import("@/view/product/declarationInfo"),
                        children: [{
                            path: 'AddDeclared',
                            name: 'AddDeclared',
                            meta: {
                                show: false,
                                title: '商品赋码详情',
                                hidden: false
                            },
                            component: () => import("@/view/product/declaredGoods/AddDeclared")
                        }],
                    }
                ]

            },
            {
                path: 'declaredGoods',
                name: 'declaredGoods',
                meta: {
                    title: '商品赋码',
                    hidden: true
                },
                component: () => import("@/view/product/declaredGoods"),
                children: [{
                        path: 'AddDeclared1',
                        name: 'AddDeclared1',
                        meta: {
                            title: '商品赋码详情',
                            hidden: false
                        },
                        component: () => import("@/view/product/declaredGoods/AddDeclared")
                    },
                    {
                        path: 'coding',
                        name: 'coding',
                        meta: {
                            title: '赋码',
                            hidden: false,
                        },
                        component: () => import("@/view/product/coding")
                    },{
                        path:'plugCode',
                        name:'plugCode',
                        meta:{
                            title:'补码',
                            hidden:false
                        },
                        component:()=> import("@/view/product/coding/plugCode.vue")
                    }
                ]
            },
            {
                meta: {
                    title: '赋码记录',
                    hidden:true
                },
                path: 'codeRecord',
                name: "codeRecord",
                component: () => import("@/view/product/codeRecord")
            }
        ]
    },
    {
        name: 'setUp',
        path: '/setUp',
        redirect: '/setUp/category',
        meta: {
            title: '系统设置',
            // disabled:true
        },
        component: Layout,
        children: [{
                meta: {
                    title: '商品类别',
                    hidden: true
                },
                path: 'category',
                name: 'category',
                component: () => import("@/view/set/category")
            },
            {
                name: 'pinpai',
                path: 'pinpai',

                component: () => import("@/view/set/brand"),
                meta: {
                    hidden: true,
                    title: '品牌',
                    // disabled: true
                }
            }
        ]
    }
]



const router = createRouter({
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

export default router
// export routes;