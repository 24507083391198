import router from "./index";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({
    showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login', '/option'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start();
    // next()
    // NProgress.done()

    // return false;
    next()
    // NProgress.done()
    return false;
    const hasToken = localStorage.getItem('user')
    if (hasToken) {
        if (whiteList.indexOf(to.path) == -1) {
            next();
            NProgress.done()
        } else {
            next({
                path: '/'
            })
            NProgress.done()
        }
    }


    // else {
    //     next()
    //     NProgress.done()
    // }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})