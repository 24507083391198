<template>
  <div class="nav">
    <div class="logo">
      <img :src="url" alt="">
      服商码</div>
    <div>
      <a-dropdown>
        <a-avatar>
          <template #icon><UserOutlined /></template>
        </a-avatar>
        <!-- <a class="ant-dropdown-link" @click.prevent>
          Hover me
          <DownOutlined />
        </a> -->
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <span @click="outLogin">退出登录</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
import { UserOutlined } from "@ant-design/icons-vue";

export default {
  data() {
    return {
      url:require("@/assets/image/logo_ma.png")
    };
  },
  methods: {
    outLogin() {
      localStorage.clear();
      this.$router.push("/option");
    },
  },
  components: {
    UserOutlined,
  },
};
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  padding: 0 24px;
  height: 63px;
  line-height: 63px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 8px #f0f1f2;
}
.logo{
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  img{
    width: 35px;
    margin-right: 10px;
    height: 35px;
  }
}
</style>