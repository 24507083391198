<template>
  <div class="breadcrumb">
    <a-breadcrumb>
      <a-breadcrumb-item
        @click="goTo(item.path)"
        v-for="(item, index) in routes"
        >{{ item.meta.title }}</a-breadcrumb-item
      >
      <!-- <a-breadcrumb-item><a href="">Application Center</a></a-breadcrumb-item> -->
      <!-- <a-breadcrumb-item><a href="">Application List</a></a-breadcrumb-item> -->
      <!-- <a-breadcrumb-item>An Application</a-breadcrumb-item> -->
    </a-breadcrumb>
    <!-- <a-breadcrumb :routes="routes">
      <template #itemRender="{ route, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span>
        <span @click="goTo(paths[paths.length - 1])">
          {{ route.meta.title }}
          {{ paths[paths.length - 1] }}
        </span>
        <router-link :to="paths[paths.length - 1]">
          {{ route.meta.title }} {{ paths[paths.length - 1] }}
        </router-link>
      </template>
      <template #itemRender="{ route, meta }">
        <router-link :to="`${basePath}/${paths.join('/')}`">
          {{ route.meta.title }}
          {{route}}
        </router-link>
      </template>
    </a-breadcrumb> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      routes: [],
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  mounted() {
    this.routes = this.$route.matched;
    // this.getBreadcrumb();
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
      // let arr = url.split("/");
      // console.log(arr)
      // if (arr.length >= 1) {
      //     console.log(arr[arr.length - 1])
      //   this.$router.push(url);
      // }
      //   console.log(url.split("/")[1]);
      //   this.$router.push(url.join('/'));
    },
    getBreadcrumb() {
      // this.routes = this.$route.matched;
      // this.routes = this.$route.matched;
      // return;
      // console.log(this.routes[0])
      // console.log(this.$route.matched[0])
      // return;
      if (this.routes[0].path == this.$route.matched[0].path) {
        let ok = false;
        let inx;
        this.routes.forEach((e, index) => {
          if (e.path == this.$route.path) {
            ok = true;
            inx = index;
          }
        });
        if (!ok) {
          this.routes.push(this.$route);
        }
        if (inx) {
          this.routes.splice(inx + 1, this.routes.length - 1);
        }
      } else {
        this.routes = this.$route.matched;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  width: 100%;
  height: 50px;
  // background-color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>