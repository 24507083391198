<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
// 中文配置
import moment from "moment";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import "moment/dist/locale/zh-cn";
moment.locale(zhCN.locale);
export default {
  name: "App",
  components: {},
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  overflow: hidden;
}
</style>
