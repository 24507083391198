<template>
  <div class="center">
    <NavBar />
    <div class="app-main">
      <SideBar />
      <div class="main">
        <Breadcrumb />
        <router-view v-slot="{ Component, route }" :key="keys">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
        <!-- <router-view :key="keys">
          <transition name="fade-transform">
            <component :is="Component" />
          </transition>
        </router-view> -->
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/layout/navBar";
import SideBar from "@/layout/sideBar";
import Breadcrumb from "@/layout/Breadcrumb";
export default {
  components: {
    NavBar,
    SideBar,
    Breadcrumb,
  },
  computed: {
    keys() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss" scoped>
.app-main {
  overflow: hidden;
  display: flex;
  .sideBar {
    width: 300px;
  }
  .main {
    width: 100%;
  }
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
// .nested-enter-active,
// .nested-leave-active {
//   transition: all 0.3s ease-in-out;
// }
// /* delay leave of parent element */
// .nested-leave-active {
//   transition-delay: 0.25s;
// }

// .nested-enter-from,
// .nested-leave-to {
//   transform: translateY(30px);
//   opacity: 0;
// }
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
</style>