<template>
  <div class="sideBar">
    <!-- <a-button
      type="primary"
      style="margin-bottom: 16px"
      @click="toggleCollapsed"
    >
      <MenuUnfoldOutlined v-if="collapsed" />
      <MenuFoldOutlined v-else />
    </a-button> -->
    <div style="width: 150px" v-if="list.length > 0">
      <a-menu
        @click="checkList"
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        mode="inline"
        theme="dark"
        :inline-collapsed="collapsed"
      >
        <div style="height: 100px; line-height: 100px; text-align: center">
          <img :src="url" alt="" />
        </div>
        <a-menu-item
          :disabled="item.meta.disabled"
          v-for="(item, index) in list"
          :key="index"
          :path="item.path"
        >
          <!-- <template #icon> {{ item.icon }} </template> -->
          <span>{{ item.meta.title }}</span>
        </a-menu-item>
      </a-menu>
    </div>
    <div style="width: 120px" v-if="list1.length > 0">
      <a-menu
        @click="checkList1"
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys1"
        mode="inline"
        theme="light"
      >
        <a-menu-item
          :disabled="item.meta.disabled"
          v-for="(item, index) in list1"
          :key="index"
          :path="item.path"
        >
          <!-- <template #icon> {{ item.icon }} </template> -->
          <span>{{ item.meta.title }}</span>
        </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
// const pathToRegexp = require("path-to-regexp");
export default defineComponent({
  //   components: {
  //     MenuFoldOutlined,
  //     MenuUnfoldOutlined,
  //     PieChartOutlined,
  //     MailOutlined,
  //     DesktopOutlined,
  //     InboxOutlined,
  //     AppstoreOutlined,
  //   },
  data() {
    return {
      list: [],
      list1: [],
      url: require("@/assets/image/logo_ma.png"),
    };
  },
  mounted() {
    const route = this.$route;
    const { path } = route;
    // 侧边导航列表
    let arr = [];
    let router = this.$router.options.routes;
    router.forEach((e) => {
      if (e.meta) {
        arr.push(e);
      }
    });
    this.list = arr;
    arr.forEach((e, index) => {
      if (path.indexOf(e.path) >= 0) {
        this.selectedKeys = [index];
        e.children.forEach((item, inx) => {
          this.list1 = e.children;
          if (path.indexOf(item.path) >= 0) {
            this.selectedKeys1 = [inx];
          }
        });
      }
    });
  },
  methods: {
    checkList(e) {
      this.list1 = this.list[e.key].children;
      this.selectedKeys1 = [0];
      this.selectedKeys = e.keyPath;
      this.$router.push(e.item.path);
    },
    // pathCompile(path) {
    //   // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    //   const { params } = this.$route;
    //   var toPath = pathToRegexp.compile(path);
    //   return toPath(params);
    // },
    checkList1(e) {
      let path = this.list[this.selectedKeys[0]].path;
      console.log(e.item.path)

      this.$router.push(path + "/" + e.item.path);

      // this.$router.push(e.item.path);
    },
  },
  setup() {
    const state = reactive({
      collapsed: false,
      selectedKeys: [0],
      selectedKeys1: [0],
      openKeys: ["sub1"],
      preOpenKeys: ["sub1"],
    });
    // const route = useRoute()
    watch(
      () => state.openKeys,
      (_val, oldVal) => {
        console.log(oldVal);
        console.log(_val);
        state.preOpenKeys = oldVal;
      }
    );
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      //   state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };

    return { ...toRefs(state), toggleCollapsed };
  },
});
</script>
<style lang="scss" scoped>
.sideBar {
  overflow: hidden;
  display: flex;
  height: calc(100vh - 63px);
  .ant-menu {
    height: 100%;
  }
}
img {
  width: 50px;
  height: 50px;
}
</style>